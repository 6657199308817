import { useQuery } from '@tanstack/react-query';
import { minutesToMilliseconds } from 'date-fns';
import { getHostFromWindow } from '@qb/frontend/utils/hosts/getHostFromWindow';
import { spsQueries } from '@qb/httpRequest/spsQueries';
import { TenantAlias } from '@/shared/tenants-alias-map';

export const usePartsAutocompleteRequest = (
  query: string,
  tenantAlias: TenantAlias,
) => {
  const { isLoading: arePartsLoading, data: parts = [] } = useQuery({
    ...spsQueries.search.partAutocomplete(
      getHostFromWindow(),
      tenantAlias,
      query,
    ),
    keepPreviousData: true,
    staleTime: minutesToMilliseconds(1),
    select: (data) => data.parts,
    enabled: Boolean(query),
  });

  return { arePartsLoading, parts };
};
